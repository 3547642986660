import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { graphql, navigate, PageProps } from 'gatsby'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import Fuse from 'fuse.js'
import moment from 'moment'
import business from 'moment-business'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  WpCourseConnection,
  WpInstructorConnection,
  WpSimpleProductConnection,
  WpSimpleProductEdge,
} from '../../graphql-types'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { AddToCart } from '../components/addToCart'
import { useQuery } from '@apollo/client'
import { PRODUCT_ATTRIBUTES } from '../graphql/query/product'
import { PRODUCTS } from '../graphql/query/product'
import { ProductList } from '../components/productList'

type DataProps = {
  allWpSimpleProduct: WpSimpleProductConnection
  allWpInstructor: WpInstructorConnection
  allWpCourse: WpCourseConnection
}

const ProductsPage: FunctionComponent<PageProps<DataProps>> = ({ data }) => {
  const {
    allWpSimpleProduct: { edges: products },
    allWpInstructor: { edges: instructors },
    allWpCourse: { edges: courses },
  } = data

  const toast = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [instructorFilter, setInstructorFilter] = useQueryParam(
    'instructor',
    StringParam
  )
  const [courseFilter, setCourseFilter] = useQueryParam('course', StringParam)
  const [dateFilter, setDateFilter] = useQueryParam('date', StringParam)
  const [mergedProducts, setmergedProducts] = useState(
    [] as WpSimpleProductEdge[]
  )
  const [filteredProducts, setFilteredProducts] = useState(
    [] as WpSimpleProductEdge[]
  )

  const {
    loading: loadingAttributes,
    error: errorAttributes,
    data: dataAttributes,
  } = useQuery<{
    products: WpSimpleProductConnection
  }>(PRODUCT_ATTRIBUTES, {
    variables: { where: { stockStatus: 'IN_STOCK' } },
  })

  // useEffect(() => {
  //   setIsLoading(loadingProducts)

  //   if (errorProducts) {
  //     toast({
  //       title: 'This is embarassing',
  //       description: 'We were unable to load the products',
  //       status: 'error',
  //     })
  //   } else if (!loadingProducts && dataProducts) {
  //     setProducts(dataProducts.products.edges)
  //   }
  // }, [dataProducts, loadingProducts, errorProducts])

  useEffect(() => {
    if (errorAttributes) {
      toast({
        title: 'This is embarassing',
        description: 'We were unable to load the product prices',
        status: 'error',
      })
    } else {
      const mergedProducts = products.map((product) => {
        const matchingEdges = dataAttributes?.products.edges.filter(
          (productAttribute) => {
            return productAttribute.node.databaseId == product.node.databaseId
          }
        )
        return matchingEdges?.length
          ? { node: { ...product.node, ...matchingEdges[0].node } }
          : product
      })
      setmergedProducts(mergedProducts)
    }
  }, [dataAttributes, loadingAttributes, errorAttributes])

  useEffect(() => {
    const filtered = mergedProducts
      .filter(({ node: { courseDetails } }) => {
        return courseDetails?.bookingType !== 'group'
      })
      .filter(({ node: { courseDetails } }) => {
        return (
          moment(courseDetails?.sessions?.at(0)?.startTime) >
          business.addWeekDays(moment(), 3)
        )
      })
      .filter(({ node: { courseDetails } }) => {
        return instructorFilter
          ? courseDetails?.instructor?.id === instructorFilter
          : true
      })
      .filter(({ node: { courseDetails } }) => {
        return courseFilter
          ? courseDetails?.courseType?.id === courseFilter
          : true
      })
      .filter((product) => {
        if (!dateFilter) return true

        const earlyDate = moment(dateFilter).subtract(7, 'days')
        const lateDate = moment(dateFilter).add(7, 'days')

        const hasBetween = product.node.courseDetails?.sessions?.reduce(
          (acc: boolean, date: any) => {
            const isBetween = moment(date?.startTime).isBetween(
              earlyDate,
              lateDate,
              'day',
              '[]'
            )
            return acc || isBetween
          },
          false
        )

        return hasBetween
      })

    setFilteredProducts(filtered)
  }, [courseFilter, dateFilter, instructorFilter, mergedProducts])

  const fuse = new Fuse(filteredProducts, {
    keys: [
      'node.courseDetails.courseType.title',
      'node.courseDetails.presentationMethod',
      'node.courseDetails.locationShortname',
      'node.courseDetails.instructor.title',
    ],
    ignoreLocation: true,
    threshold: 0.1,
  })

  const searchResults = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : filteredProducts

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value)
  }

  const handleInstructor = (event: ChangeEvent<HTMLSelectElement>) => {
    setInstructorFilter(event.currentTarget.value)
  }

  const handleCourse = (event: ChangeEvent<HTMLSelectElement>) => {
    setCourseFilter(event.currentTarget.value)
  }

  const handleDate = (event: ChangeEvent<HTMLInputElement>) => {
    setDateFilter(event.currentTarget.value)
  }

  const handleClear = () => {
    setInstructorFilter(undefined)
    setCourseFilter(undefined)
    setDateFilter(undefined)
  }

  return (
    <Layout>
      <SEO title="Products" />

      {/* <Input
        maxW="80"
        onChange={handleSearch}
        type="search"
        value={searchQuery}
      /> */}
      <Container maxW="4xl">
        <Grid gridGap="12">
          <Heading size="lg">Find the right course for you...</Heading>
          <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
            <Heading mb="4" size="md">
              Filter Courses
            </Heading>
            <Grid
              gridRowGap={{ base: '2' }}
              gridColumnGap={{ base: '2', md: '8' }}
              gridTemplateColumns={{
                base: 'auto',
                md: 'auto 1fr',
                lg: 'auto auto 1fr',
              }}
            >
              <FormControl>
                <FormLabel>Course</FormLabel>
                <Select
                  maxW="80"
                  onChange={handleCourse}
                  value={courseFilter || ''}
                >
                  <option value="">All courses</option>
                  {courses.map(({ node }, i) => {
                    return (
                      <option key={i} value={node.id}>
                        {node.title}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              {/* <FormControl>
                <FormLabel>Instructor</FormLabel>
                <Select
                  maxW="40"
                  onChange={handleInstructor}
                  value={instructorFilter || ''}
                >
                  <option value="">All instructors</option>
                  {instructors.map(({ node }, i) => {
                    return (
                      <option key={i} value={node.id}>
                        {node.title}
                      </option>
                    )
                  })}
                </Select>
              </FormControl> */}
              <FormControl>
                <FormLabel>Date</FormLabel>
                <Grid
                  alignItems="center"
                  gridGap={3}
                  gridTemplateColumns="auto 1fr"
                >
                  <Input
                    type="date"
                    maxW="48"
                    onChange={handleDate}
                    value={dateFilter || ''}
                  />
                  <Text>± 7 days</Text>
                </Grid>
              </FormControl>
              <Button
                alignSelf="flex-end"
                color="msuGreen.400"
                colorScheme="aqua"
                disabled={!courseFilter && !instructorFilter && !dateFilter}
                justifySelf={{ base: 'flex-start', lg: 'flex-end' }}
                mt={{ base: '2', lg: '0' }}
                onClick={handleClear}
                variant="outline"
              >
                Clear filters
              </Button>
            </Grid>
          </Box>
          <Box backgroundColor="whiteAlpha.900" borderRadius="2" p="4">
            {searchResults.length > 0 ? (
              <Grid gridGap="4">
                {searchResults.map(({ node }, index) => {
                  return (
                    <ProductList
                      isLast={index === searchResults.length - 1}
                      key={index}
                      {...node}
                    />
                  )
                })}
              </Grid>
            ) : (
              <Stack alignItems="center" direction="column" py="2" spacing="4">
                <Text>
                  We currently have no courses that match your criteria
                </Text>
                <Button
                  color="msuGreen.400"
                  colorScheme="aqua"
                  onClick={handleClear}
                  variant="outline"
                >
                  Clear filters
                </Button>
              </Stack>
            )}
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default ProductsPage

// filter: { stockStatus: { eq: IN_STOCK } }

export const query = graphql`
  query wpProducts {
    allWpSimpleProduct(sort: { fields: courseDetails___sessions___startTime }) {
      edges {
        node {
          databaseId
          id
          link
          name
          courseDetails {
            sessions {
              duration
              startTime
            }
            courseType {
              ... on WpCourse {
                id
                slug
                title
              }
            }
            instructor {
              ... on WpInstructor {
                id
                title
              }
            }
            bookingType
            locationShortname
            presentationMethod
          }
        }
      }
    }
    allWpCourse {
      edges {
        node {
          id
          title
        }
      }
    }
    allWpInstructor {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`
