import React, { Fragment, FunctionComponent } from 'react'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Box, Button, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { WpSimpleProduct } from '../../../graphql-types'
import { AddToCart } from '../addToCart'

import { formattedSessions } from '../../utils'

interface productListProps extends WpSimpleProduct {
  hasBooking?: boolean
  hasDetails?: boolean
  hasTitle?: boolean
  isHero?: boolean
  isFoot?: boolean
  isLast?: boolean
}

export const ProductList: FunctionComponent<productListProps> = (props) => {
  const {
    courseDetails,
    hasBooking = true,
    hasDetails = true,
    hasTitle,
    id,
    isHero,
    isFoot,
    isLast,
    link,
    name,
    onSale,
    price,
    regularPrice,
    salePrice,
  } = props

  return (
    <Fragment>
      <Grid
        alignItems="flex-start"
        gridGap="4"
        gridTemplateColumns={['auto', '1fr auto']}
      >
        <Grid gridTemplateColumns={{ base: 'auto', lg: 'auto 1fr' }}>
          <Grid>
            {isHero || isFoot ? (
              <>
                {!isFoot ? (
                  <Heading as="h1" mb="2" size="xl">
                    {name}
                  </Heading>
                ) : null}

                <Heading mb="1" size="md">
                  {formattedSessions(courseDetails?.sessions)}
                </Heading>
                <Text>
                  {courseDetails?.bookingType === 'group' ||
                  courseDetails?.presentationMethod === 'Online'
                    ? courseDetails?.presentationMethod
                    : courseDetails?.locationShortname}
                  {courseDetails?.instructor?.title &&
                    ` - ${courseDetails?.instructor?.title}`}
                </Text>
              </>
            ) : (
              <>
                <Grid alignItems="center" height="10">
                  <Heading size="md">{name}</Heading>
                </Grid>
                <Text>{formattedSessions(courseDetails?.sessions)}</Text>
                <Text>
                  {courseDetails?.bookingType === 'group' ||
                  courseDetails?.presentationMethod === 'Online'
                    ? courseDetails?.presentationMethod
                    : courseDetails?.locationShortname}
                  {courseDetails?.instructor?.title &&
                    ` - ${courseDetails?.instructor?.title}`}
                </Text>
              </>
            )}
          </Grid>
        </Grid>

        <Stack direction={{ base: 'column', md: 'row' }} spacing="2">
          {hasBooking ? (
            <>
              <Grid
                alignItems="center"
                alignSelf="flex-start"
                gridTemplateColumns={onSale ? 'auto auto' : 'auto'}
                justifySelf={{ base: 'flex-end', lg: 'flex-end' }}
                gridGap="2"
                height="10"
                mr="2"
              >
                <Text
                  fontSize={onSale ? 'md' : 'lg'}
                  textDecoration={onSale ? 'line-through' : 'none'}
                >
                  {onSale ? regularPrice : price}
                </Text>
                {salePrice ? (
                  <Text fontSize="lg" fontWeight="bold">
                    {salePrice}
                  </Text>
                ) : null}
              </Grid>
              <AddToCart id={id} />
            </>
          ) : null}

          {hasDetails ? (
            <Button
              aria-label={`Details about ${name}`}
              color="msuGreen.400"
              colorScheme="aqua"
              variant="outline"
              onClick={() => {
                navigate(link!)
              }}
            >
              Details
            </Button>
          ) : null}
        </Stack>
      </Grid>
      {!isLast ? (
        <Box backgroundColor="msuGreen.200" height="1px" width="100%" />
      ) : null}
    </Fragment>
  )
}
