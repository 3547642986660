import React, { FunctionComponent, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Grid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from '@chakra-ui/react'

import { useAppState } from '../context'
import { ADD_TO_CART } from '../../graphql/mutation/cart'
import { WpSimpleProduct } from '../../../graphql-types'
import { PRODUCT_STOCK } from '../../graphql/query/product'

export const AddToCart: FunctionComponent<{ id: string }> = ({ id }) => {
  const toast = useToast()
  const { cart, setCart } = useAppState()
  const [quantity, setQuantity] = useState(0)
  const cartNode = cart?.contents?.nodes?.filter((item) => {
    return item?.product?.node?.id == id
  })[0]

  const {
    loading: loadingStock,
    error: errorStock,
    data: dataStock,
  } = useQuery<{ product: WpSimpleProduct }>(PRODUCT_STOCK, {
    variables: { id },
  })

  const [addToCart, { loading }] = useMutation(ADD_TO_CART, {
    onCompleted: ({ addToCart }) => {
      toast({
        title: `${quantity} Item${quantity > 1 ? 's' : ''} added to cart`,
        description: `${dataStock?.product.courseDetails?.courseType?.title}`,
        status: 'success',
      })
      setCart(addToCart.cart)
      setQuantity(
        dataStock?.product.stockQuantity &&
          dataStock?.product.stockQuantity -
            (cartNode?.quantity || 0) -
            quantity >
            0
          ? 1
          : 0
      )
    },
    onError: () => {
      toast({
        title: 'Error',
        description: 'There was an error adding your product',
        status: 'error',
      })
    },
  })

  useEffect(() => {
    setQuantity(
      dataStock?.product.stockQuantity &&
        dataStock?.product.stockQuantity - (cartNode?.quantity || 0) > 0
        ? 1
        : 0
    )
  }, [dataStock])

  function handleAddToCart() {
    addToCart({
      variables: {
        input: {
          productId: dataStock?.product.databaseId,
          quantity: quantity,
          clientMutationId: 'AddToCart',
        },
      },
    })
  }

  if (loadingStock) {
    return (
      <Grid gridGap="2" gridTemplateColumns="auto 1fr">
        <NumberInput defaultValue={quantity} isDisabled={true} maxW="20">
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Button color="msuGreen" colorScheme="aqua" disabled={true} minW="32">
          Add to cart
        </Button>
      </Grid>
    )
  }

  if (
    !dataStock?.product.stockQuantity ||
    dataStock?.product.stockQuantity == 0
  ) {
    return (
      <Grid gridGap="2" gridTemplateColumns="auto 1fr">
        <NumberInput defaultValue={quantity} isDisabled={true} maxW="20">
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Button color="msuGreen" colorScheme="aqua" disabled={true} minW="32">
          Sold Out
        </Button>
      </Grid>
    )
  }

  return (
    <Grid gridGap="2" gridTemplateColumns="auto 1fr">
      <NumberInput
        isDisabled={loadingStock}
        min={
          dataStock?.product.stockQuantity - (cartNode?.quantity || 0) > 0
            ? 1
            : 0
        }
        max={dataStock?.product.stockQuantity - (cartNode?.quantity || 0)}
        maxW="20"
        onChange={(value) => setQuantity(parseInt(value))}
        value={quantity}
      >
        <NumberInputField bg="white" />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Button
        color="msuGreen"
        colorScheme="aqua"
        disabled={
          cartNode?.quantity && dataStock?.product.stockQuantity
            ? cartNode.quantity >= dataStock.product.stockQuantity
            : false
        }
        onClick={() => handleAddToCart()}
        isLoading={loading}
        loadingText=""
        minW="32"
      >
        Add to cart
      </Button>
    </Grid>
  )
}
