import { gql } from '@apollo/client'

export const PRODUCT_ATTRIBUTES = gql`
  query getProductAttributes {
    products(first: 100) {
      edges {
        node {
          databaseId
          id
          name
          ... on SimpleProduct {
            price
            onSale
            salePrice
            regularPrice
            stockQuantity
          }
        }
      }
    }
  }
`

export const PRODUCT_STOCK = gql`
  query getProductStock($id: ID!) {
    product(id: $id) {
      courseDetails {
        courseType {
          ... on Course {
            title
          }
        }
      }
      databaseId
      ... on SimpleProduct {
        stockQuantity
      }
    }
  }
`

export const PRODUCTS = gql`
  query getProducts($where: RootQueryToProductConnectionWhereArgs!) {
    products(where: $where) {
      edges {
        node {
          databaseId
          id
          link
          name
          courseDetails {
            sessions {
              duration
              startTime
            }
            locationShortname
            presentationMethod
            courseType {
              ... on Course {
                id
                title
              }
            }
            instructor {
              ... on Instructor {
                id
                title
              }
            }
          }
          ... on SimpleProduct {
            price
            onSale
            salePrice
            regularPrice
            stockQuantity
          }
        }
      }
    }
  }
`
